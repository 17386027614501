import { createSelector, createSlice } from "@reduxjs/toolkit";
import { timeout } from "services/helpers/fake-asset-generators";
import { callAPIFunction, callAPIProps } from "services/hooks/useAPI";
import { userSettingsInterfaceCalculationListSelector } from "state/user/userSettingsSlice";

export type SellerProps = {
    id: string;
    user_id: number;
    leader_id?: number;
    code: string;
    settings: {
        baseProvision: number;
        teamProvision: number;
    };
    created_at: string;
    updated_at: string;
    coupons?: CouponsProps;
    subscriptions?: SellerSubscriptionProps[];
    calculations?: CalculationProps[];
};

type CouponsProps = {

};

type SellerSubscriptionProps = {

};

type CalculationProps = {
    type: string;
    attributes: {
        start: string;
        end: string;
        paid: CalculationDataProps
        unpaid: CalculationDataProps
    }
};

export type TransactionProps = {
    amount: number;
    status?: "paid" | "unpaid"
    type?: "calculations" | "team-calculations"
    subscription: {
        name: string;
        creation_time: string;
        additional: string;
    };
    user: {
        name: string;
        email: string;
    };
    title: string;
};

type CalculationDataProps = {
    total: number;
    data: TransactionProps[];
};

export type CalculationListFilter = {
    type:{
        calculations: boolean,
        teamCalculations: boolean
    },
    status:{
        paid: boolean
        unpaid: boolean
    }
}


const initialState: Partial<SellerProps> = {};

export const sellerSlice = createSlice({
    name: "seller",
    initialState,
    reducers: {
        getSeller: (state, action) => {
            return { ...state, ...action.payload.data };
        },
        getCalculations: (state, action) => {
            state.calculations = action.payload.data;
        },
        clearCalculations: (state, action) => {
            state.calculations = []
        },
        getCoupons: (state, action) => {
            state.coupons = action.payload.data;
        },
        getSubscriptions: (state, action) => {
            state.subscriptions = action.payload.data;
        },
    },
});


export const SortCalculationsListByDate = (calcs: any[], newestFirst: boolean) =>
    calcs &&
    calcs.sort((a: any, b: any) => {
        const aDate = new Date(a.created_at);
        const bDate = new Date(b.created_at);
        if (newestFirst) return bDate.getTime() - aDate.getTime();
        return aDate.getTime() - bDate.getTime();
    });

export const GetSeller: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`seller`, 1),
    method: 'GET',
    successDispatch: sellerSlice.actions.getSeller,
}

export const GetSellerCalculations: callAPIFunction = (query?: any): callAPIProps => {

    const call = {
        url: ({ getApiUrl, serializeQuery }) => getApiUrl(`seller/calculations${query ? "?" + serializeQuery(query) : ""}`, 1),
        method: "GET",
        successDispatch: sellerSlice.actions.getCalculations,
        passToDispatcher: query,
        customNormalizer: (data:any) => data.data
    }

    if (import.meta.env.VITE_DEBUG_FAKE_SELLER_CALCULATIONS === "true") {
        return {
            ...call,
            fakeResponse: async (request) => await timeout(500).then(() => GenerateFakeCalculations()),
        }
    }

    return call;

};

export const GetSellerCoupons: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`seller/coupons`, 1),
    method: 'GET',
    successDispatch: sellerSlice.actions.getCoupons,
}

export const GetSellerSubscriptions: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`seller/subscriptions`, 1),
    method: 'GET',
    successDispatch: sellerSlice.actions.getSubscriptions,
}

export const GetSellerValidate: callAPIProps = {
    url: ({ getApiUrl, projectId }: any) => getApiUrl(`seller/validate`, 1),
    method: 'GET',
    // successDispatch: sellerSlice.actions.get,
}

export const SellerSelector = (state: any) => state.seller

export const CouponsSelector = createSelector(
    SellerSelector,
    (seller) => seller.coupons
);

export const SellerSubscriptionsSelector = createSelector(
    SellerSelector,
    (seller) => seller.subscriptions
);

export const CalculationsSelector = createSelector(
    SellerSelector,
    (seller) => seller.calculations
);


export const TransactionsSelector = createSelector(
    CalculationsSelector,
    (calculations): TransactionProps[] => {
        if (!calculations || !Array.isArray(calculations)) return [];

        return calculations.flatMap((calculation) => {
            const type = calculation.type as "calculations" | "team-calculations";

            const paidTransactions = calculation.attributes.paid.data.map((transaction: TransactionProps) => ({
                ...transaction,
                status: "paid" as const,
                type,
            }));

            const unpaidTransactions = calculation.attributes.unpaid.data.map((transaction: TransactionProps) => ({
                ...transaction,
                status: "unpaid" as const,
                type,
            }));

            return [...paidTransactions, ...unpaidTransactions];
        });
    }
);
export const filteredTransactionsCountSelector = createSelector([
    TransactionsSelector,
    userSettingsInterfaceCalculationListSelector,
],
    (transactions, filters) => {
        const filteredTransactions = transactions.filter((transaction: TransactionProps) => {
            return FilterTransactions(transaction, filters)
        });
        return transactions.length - filteredTransactions.length;
    })

export const FilterTransactions = (transaction: TransactionProps, filter: any) => {
    if (!filter) return true;

    if (transaction) {

        if (filter?.type.calculations === false && transaction?.type === "calculations") return false;
        if (filter?.type.teamCalculations === false && transaction?.type === "team-calculations") return false;

        if (filter?.status.paid === false && transaction?.status === "paid") return false;
        if (filter?.status.unpaid === false && transaction?.status === "unpaid") return false;

    }
    return true;
}

export const GenerateFakeCalculations = () => {

    const fakeData = [
        {
            type: "calculations",
            attributes: {
                start: "2024-10-01T00:00:00.000000Z",
                end: "2024-10-31T23:59:59.999999Z",
                paid: {
                    total: 7,
                    data: [
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-10-31T15:29:08.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Piotr Basista",
                                email: "topmedica@o2.pl"
                            },
                            title: "Prowizja za klienta Piotr Basista topmedica@o2.pl - Smart 2023-10-31 15:29:08"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-11-10T09:24:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - Smart 2023-11-10 09:24:24"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-11-10T09:43:49.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - LeadAds - monthly 2023-11-10 09:43:49"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "CallApp Monthly",
                                creation_time: "2023-11-10T10:03:04.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - CallApp Monthly 2023-11-10 10:03:04"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-12-06T11:22:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Żaneta Kalecińska",
                                email: "zkalecinska88@interia.pl"
                            },
                            title: "Prowizja za klienta Żaneta Kalecińska zkalecinska88@interia.pl - Smart 2023-12-06 11:22:24"
                        },
                        {
                            amount: 177.12,
                            subscription: {
                                name: "Pakiet Local 2",
                                creation_time: "2023-12-29T13:41:29.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - Pakiet Local 2 2023-12-29 13:41:29"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-12-29T13:50:12.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - LeadAds - monthly 2023-12-29 13:50:12"
                        }
                    ]
                },
                unpaid: {
                    total: 7,
                    data: [
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-10-31T15:29:08.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Piotr Basista",
                                email: "topmedica@o2.pl"
                            },
                            title: "Prowizja za klienta Piotr Basista topmedica@o2.pl - Smart 2023-10-31 15:29:08"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-11-10T09:24:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - Smart 2023-11-10 09:24:24"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-11-10T09:43:49.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - LeadAds - monthly 2023-11-10 09:43:49"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "CallApp Monthly",
                                creation_time: "2023-11-10T10:03:04.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - CallApp Monthly 2023-11-10 10:03:04"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-12-06T11:22:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Żaneta Kalecińska",
                                email: "zkalecinska88@interia.pl"
                            },
                            title: "Prowizja za klienta Żaneta Kalecińska zkalecinska88@interia.pl - Smart 2023-12-06 11:22:24"
                        },
                        {
                            amount: 177.12,
                            subscription: {
                                name: "Pakiet Local 2",
                                creation_time: "2023-12-29T13:41:29.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - Pakiet Local 2 2023-12-29 13:41:29"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-12-29T13:50:12.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - LeadAds - monthly 2023-12-29 13:50:12"
                        }
                    ]
                }
            }
        },
        {
            type: "team-calculations",
            attributes: {
                start: "2024-10-01T00:00:00.000000Z",
                end: "2024-10-31T23:59:59.999999Z",
                paid: {
                    total: 7,
                    data: [
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-10-31T15:29:08.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Piotr Basista",
                                email: "topmedica@o2.pl"
                            },
                            title: "Prowizja za klienta Piotr Basista topmedica@o2.pl - Smart 2023-10-31 15:29:08"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-11-10T09:24:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - Smart 2023-11-10 09:24:24"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-11-10T09:43:49.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - LeadAds - monthly 2023-11-10 09:43:49"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "CallApp Monthly",
                                creation_time: "2023-11-10T10:03:04.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - CallApp Monthly 2023-11-10 10:03:04"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-12-06T11:22:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Żaneta Kalecińska",
                                email: "zkalecinska88@interia.pl"
                            },
                            title: "Prowizja za klienta Żaneta Kalecińska zkalecinska88@interia.pl - Smart 2023-12-06 11:22:24"
                        },
                        {
                            amount: 177.12,
                            subscription: {
                                name: "Pakiet Local 2",
                                creation_time: "2023-12-29T13:41:29.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - Pakiet Local 2 2023-12-29 13:41:29"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-12-29T13:50:12.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - LeadAds - monthly 2023-12-29 13:50:12"
                        }
                    ]
                },
                unpaid: {
                    total: 7,
                    data: [
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-10-31T15:29:08.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Piotr Basista",
                                email: "topmedica@o2.pl"
                            },
                            title: "Prowizja za klienta Piotr Basista topmedica@o2.pl - Smart 2023-10-31 15:29:08"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-11-10T09:24:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - Smart 2023-11-10 09:24:24"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-11-10T09:43:49.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - LeadAds - monthly 2023-11-10 09:43:49"
                        },
                        {
                            amount: 90.405,
                            subscription: {
                                name: "CallApp Monthly",
                                creation_time: "2023-11-10T10:03:04.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Mateusz Jamrozik",
                                email: "mjamrozikfizjo@wp.pl"
                            },
                            title: "Prowizja za klienta Mateusz Jamrozik mjamrozikfizjo@wp.pl - CallApp Monthly 2023-11-10 10:03:04"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "Smart",
                                creation_time: "2023-12-06T11:22:24.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Żaneta Kalecińska",
                                email: "zkalecinska88@interia.pl"
                            },
                            title: "Prowizja za klienta Żaneta Kalecińska zkalecinska88@interia.pl - Smart 2023-12-06 11:22:24"
                        },
                        {
                            amount: 177.12,
                            subscription: {
                                name: "Pakiet Local 2",
                                creation_time: "2023-12-29T13:41:29.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - Pakiet Local 2 2023-12-29 13:41:29"
                        },
                        {
                            amount: 100.86,
                            subscription: {
                                name: "LeadAds - monthly",
                                creation_time: "2023-12-29T13:50:12.000000Z",
                                additional: ""
                            },
                            user: {
                                name: "Magdalena Szym",
                                email: "rzysiam@gmail.com"
                            },
                            title: "Prowizja za klienta Magdalena Szym rzysiam@gmail.com - LeadAds - monthly 2023-12-29 13:50:12"
                        }
                    ]
                }
            }
        },
    ]


    return {
        status: 200,
        json: () => ({
            data: fakeData
        })
    }
}