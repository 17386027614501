import React, { ReactNode, Component, PropsWithChildren } from "react";
import Helper from "providers/routing/routes/helper";
import { Providers } from "modules/index";
import {
  getUrl as getPanelUrl,
  ONBOARDING_DYNAMIC_INTEGRATE_PLATFORMS,
  ONBOARDING_PAYMENT,
  ONBOARDING_PREPARE_PROJECT,
  ONBOARDING_COMPANY_DATA,
  ONBOARDING_UPDATE_PROJECT_STATUS,
  ONBOARDING_VERIFY_NUMBER,
  ONBOARDING_TERMS_OF_SERVICES_STATEMENT,
  ONBOARDING_MARKETING_STATEMENT,
  ONBOARDING_CONTACT_EMAIL,
} from "modules/panel/config/routes";
import {
  userHasMarketingStatement,
  userHasTermsOfServicesStatement,
  userHasAuthorizedPlatforms,
  userHasAtLeastOneCriticalEmailSelector,
  userHasPhoneVerifySelector,
} from "modules/panel/config/selectors/user";
import {
  AppConfig,
  ModulesConfig,
  Module,
  Requirement,
  Platform,
  Integration,
} from "modules";
import { Product } from "products";
import { connect } from "react-redux";
import products from "products";
import isEqual from "lodash/isEqual";
import isFreeProduct from "products/without-subscription";
import { userHasBusinessPurposesStatement } from "modules/panel/config/selectors/user";
import { GetIndustryByName } from "modules/site-generator/config/industries/IndustriesConfig";
import { WebsiteIndustrySelector } from "modules/site-generator/reducers/websiteSlice";
import { ProviderConfig } from "modules/panel/config/ProviderConfig";
import { currentFlatSubscriptionPermissionsSelector, currentSubscriptionPaidSelector, currentSubscriptionPermissionsSelector } from "state/subscriptions/subscriptionsSelectors";
import { currentProductSelector } from "services/store/scopeSlice";
import { currentSubscriptionSelector } from "state/subscriptions/subscriptionsSelectors";
import { RootState } from "services/store/redux-store";
import { currentProjectOnboarded, currentProjectSelector, userHasActiveProject } from "state/projects/projectSelectors";

export type SubscriptionContext = {
  index: string | false;
  providers: Providers;
  integration?: {
    [platform in Platform]: Integration[];
  };
  apps: AppConfig[];
  requirements: any[];
  logo: string;
  name: ReactNode | string;
};

type SubscriptionProps = {
  permissions: any;
  flatPermissions: any;
  product: Product;
  modules: ModulesConfig;
  subscriptionStatus: string;
  project: any;
  industry: string;
  render: (
    routes: AppConfig[],
    requirements: any[],
    indexRoute: string
  ) => ReactNode;
};

type Permissions = {
  integration: Platform[];
  module: {
    [key in Module]: string[];
  };
};

type State = {
  currentProduct: Product;
};

export const Context = React.createContext<SubscriptionContext>({
  apps: [],
  requirements: [],
  index: "",
  providers: [],
  logo: "",
  name: "",
});

const { Provider, Consumer } = Context;

class SubscriptionProvider extends Component<PropsWithChildren<SubscriptionProps>> {
  shouldComponentUpdate = (prevProps: SubscriptionProps) =>
    prevProps.product !== this.props.product ||
    !isEqual(prevProps.permissions, this.props.permissions) || prevProps.industry !== this.props.industry;

  getProductRequirements = (providers: Providers) => {

    const industry = this.props.industry;
    const industryRequirements = industry ? GetIndustryByName(industry).requirements : [];
    //console.log("industry requirements", industry, industryRequirements);

    const {
      free: withoutSubscription,
      requirements: productRequirements,
      project: shouldHaveProject = true,
    } = this.getCurrentProduct();

    return this.filterRequirementsByAvailableProvidersAndPermissions(
      [
        ...(this.getPaymentAndProjectRequirement(
          withoutSubscription,
          !shouldHaveProject
        ) as any),
        ...this.getProvidersRequirements(providers, withoutSubscription),
        ...(productRequirements ? productRequirements : []),
        ...(industryRequirements ? industryRequirements : []),
        {
          key: "updateProjectStatus",
          path: () => getPanelUrl(ONBOARDING_UPDATE_PROJECT_STATUS),
          selector: currentProjectOnboarded,
        },
      ],
      providers
    );
  };

  getProvidersRequirements = (
    providers: Providers,
    withouSetting?: boolean
  ) => {
    const requirements = [];

    if (Array.isArray(providers)) {
      requirements.push({
        key: "integratePlatforms",
        path: () => getPanelUrl(ONBOARDING_DYNAMIC_INTEGRATE_PLATFORMS),
        selector: userHasAuthorizedPlatforms(providers),
      });

      if (!withouSetting) {
        providers.forEach((provider) => {

          const label = ProviderConfig.get(provider)?.displayName, 
          getPath = ProviderConfig.get(provider)?.getPath , 
          filledSelector = ProviderConfig.get(provider)?.filledSelector

          const fieldsForThisProvider = (this.props.permissions as any).providers[provider];

          //console.log("CHECK", provider, fieldsForThisProvider);
          // console.log("ALL FIELDS", fields)

          const selectorWithFields = () => (state: any) => filledSelector(state, fieldsForThisProvider);
          //console.log("SELECTO WITH FIELDS", selectorWithFields())

          requirements.push({
            key: "integrate" + label,
            path: getPath,
            //selector: () => false
            selector: selectorWithFields(),
          });
        });
      }
    }

    return requirements;
  };

  getPaymentAndProjectRequirement = (
    isFree?: boolean,
    withoutProject?: boolean
  ) =>
    [
      {
        key: "userHasPhoneVerifySelector",
        path: () => getPanelUrl(ONBOARDING_VERIFY_NUMBER),
        selector: userHasPhoneVerifySelector,
        omitWhenFulfilled: true,
      },
      {
        key: "termsOfServicesStatement",
        path: () => getPanelUrl(ONBOARDING_TERMS_OF_SERVICES_STATEMENT),
        selector: userHasTermsOfServicesStatement,
        omitWhenFulfilled: true,
      },
      {
        key: "marketingStatement",
        path: () => getPanelUrl(ONBOARDING_MARKETING_STATEMENT),
        selector: userHasMarketingStatement,
        omitWhenFulfilled: false,
      },
      !withoutProject
        ? {
          key: "userHasActiveProject",
          path: () => getPanelUrl(ONBOARDING_PREPARE_PROJECT),
          selector: userHasActiveProject,
          omitWhenFulfilled: true,
        }
        : null,
        {
          key: "userHasContactEmail",
          path: () => getPanelUrl(ONBOARDING_CONTACT_EMAIL),
          selector: userHasAtLeastOneCriticalEmailSelector,
          omitWhenFulfilled: true,
        },
      !isFree
        ? {
          key: "isCurrentSubscriptionPaid",
          path: () => getPanelUrl(ONBOARDING_PAYMENT),
          selector: currentSubscriptionPaidSelector,
          omitWhenFulfilled: true,
        }
        : null,
      {
        key: "userHasBusinessPurposesStatement",
        path: () => getPanelUrl(ONBOARDING_COMPANY_DATA),
        selector: userHasBusinessPurposesStatement,
        // selector: () => false,
        omitWhenFulfilled: true,
      },
    ].filter(Boolean) as unknown as Requirement[];

  filterRequirementsByAvailableProvidersAndPermissions = (
    requirements: Requirement[],
    providers: Providers
  ) =>
    requirements.filter(({ integration, permission }) => {
      if (integration && !providers.includes(integration)) return false;

      if (permission && !this.props.flatPermissions.includes(permission))
        return false;

      return true;
    });

  getPermissionsProviders = () =>
    this.props.permissions ? this.props.permissions.integration : [];

  getCurrentProduct = () => ({
    ...products[this.getCurrentProductName()],
    free: isFreeProduct(this.props.product),
  });

  getCurrentProductName = () => this.props.product;

  getFilteredModulesRoutesByPermissions = () => {
    const permissions = this.props.permissions
      ? this.props.permissions.module
      : false;

    const modules: AppConfig[] = [];

    for (const moduleName in this.props.modules) {
      const module = this.props.modules[moduleName as Module];

      modules.push({
        ...module,
        routes: module.routes.filter(
          (route) => {

            const hasPermission = (permissions &&
                permissions[moduleName as Module] &&
                permissions[moduleName as Module].includes(route.key))

            if (route.requiredPermission) return hasPermission;
            if (moduleName === "panel") return true;
            if (route.displayInMenu === false) return true;
            return hasPermission;

          }
        ),
      });
    }

    return modules;
  };

  getIndex = () => {
    const { index } = this.getCurrentProduct();
    if (this.props.subscriptionStatus === "unpaid") return "/panel/profile";
    return index || "/panel/profile";
  };

  render() {

    // console.log("props", this.props)
    // console.log("status", this.props.subscriptionStatus)

    const {
      logo,
      name,
      free,
      providers: productProviders,
    } = this.getCurrentProduct();

    //const index = this.getIndex();

    const index = "/panel/dashboard";

    const apps = this.getFilteredModulesRoutesByPermissions();

    const providers: Providers = free
      ? productProviders || []
      : this.getPermissionsProviders();

    const requirements = this.getProductRequirements(providers);

    Helper.getPath(
      apps[apps.length - 1].path,
      apps[apps.length - 1].routes,
      apps[apps.length - 1].routes[apps.length - 1].key
    ); //TODO: circular dependencies hack!

    return (
      <Provider
        value={{
          apps,
          requirements,
          index,
          providers: providers || [],
          logo,
          name,
        }}
      >
        <div >
          {this.props.render(apps, requirements, index)}
        </div>
      </Provider>
    );
  }
}

export const SubscriptionConsumer = Consumer;

export default connect((state: any) => {
  return {
    permissions: currentSubscriptionPermissionsSelector(state),
    flatPermissions: currentFlatSubscriptionPermissionsSelector(state),
    product: currentProductSelector(state),     
    subscriptionStatus: ((state: RootState) => {
      const sub = currentSubscriptionSelector(state);
      if (!sub) return false;
      return sub.status.toLowerCase()})(state),
    project: currentProjectSelector(state),
    industry: WebsiteIndustrySelector(state),
  };
})(SubscriptionProvider);
