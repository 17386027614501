import { createSlice, current } from "@reduxjs/toolkit";
import { callAPIProps } from "services/hooks/useAPI";
import { generateFakeSubscriptions } from "./fakeSubscriptions";
import _ from "lodash";
import { Subscription, SubscriptionCancellationPlan } from "./subscriptionTypes";
import { attachSubscriptionToProject } from "./subscriptionsEndpoints";

type SubscriptionsSliceState = {
    subscriptions: Record<string, Subscription>,
    cancellationPlans: Record<string, SubscriptionCancellationPlan[]>,
};

const initialState: SubscriptionsSliceState = {
    subscriptions: {},
    cancellationPlans: {},
};

const adjustSubscription = (s: any) => {

    const currency = s.currency || s.stripe_invoices?.[0]?.currency || "PLN";

    const endDate = new Date(s.created_at);
    endDate.setTime(endDate.getTime() + 1000 * 60 * 60 * 24 * 30)

    const projectIds:string[] = (s.projects || s.project_ids || []).map((p:any) => {
        if (typeof p === "number") return String(p);
        if (typeof p === "string") return String(p);
        if (typeof p === "object") return p.id ? String(p.id) : null;
        return null;
    }).filter((p:any) => p);

    const roles:string[] = (s.roles || []).map((r:any) => {
        if (typeof r === "string") return r;
        if (typeof r === "object") return r.name;
        return null;
    })

    
    const product = s.product || roles?.[0];

    //fallback for inconsistent API
    const invoices = s.stripe_invoices || s.stripeInvoices || [];

    const totalDue = invoices.reduce((acc: any, i: any) => i.paid ? acc : acc + i.amount_due - i.amount_paid, 0);
    const oldestMissedPayment = invoices.reduce((acc: any, i: any) => {
        if (i.amount_due - i.amount_paid > 0) {
            if (!acc) return i.created_at
            if (new Date(i.created_at) < new Date(acc)) return i.created_at;
        }
        return null;
    }, null);
    const fullyPaid = totalDue === 0;

    let status = s.stripe_status;
    if (status === "cancelled") status = "canceled";
    return {
        cancellation_survey: null,
        ...s,
        projects: projectIds,
        project: projectIds?.[0],
        price: s.charge_price,
        combinedProduct: { product: product, name: s.name },
        product: product,
        roles: roles,
        _generated: {
            expirationData: s.expiry,
            totalDue,
            oldestMissedPayment,
            fullyPaid,
            currency,
            status: status,
            isInternal: s.stripe_plan === "internal",
            canBeManaged: (status === "active") || ((status === "cancelled" || status === "canceled") && new Date(s.expiry).getTime() > Date.now())
        },
        
    }

}

const addOrUpdateSubscription = (state: any, subscription: any) => {

    if (!subscription) return;
    if (!subscription.id) return;

    const sub = state.subscriptions[subscription.id];

    if (!sub) {
        state.subscriptions[subscription.id] = adjustSubscription(subscription);
        return;
    }
    state.subscriptions[subscription.id] = adjustSubscription({ ...sub, ...subscription });

}

export const subscriptionsSlice = createSlice({
    name: "subscriptionsSlice",
    initialState,
    reducers: {
        getSubscriptions: (state, action) => {
            //console.log("getSubscriptions", action.payload)
            if (action?.payload?.data && action.payload.data.forEach) {
                action.payload.data.forEach((s: any) => {
                    addOrUpdateSubscription(state, s)
                })
            }
        },
        getSubscription: (state, action) => {
            //console.log("got subscription", action.payload);
            addOrUpdateSubscription(state, action.payload.data);
        },
        newSubscription: (state, action) => {
            if (action?.payload?.data?.subscription) {
                addOrUpdateSubscription(state, action.payload.data.subscription)
            }
        },
        getCancellationPlans: (state, action) => {
            const subscriptionId = action?.payload?.additional?.subscriptionId;
            if (!subscriptionId) return;
            const plans = action.payload.data;
            if (!plans || plans.length === 0) return;
            state.cancellationPlans[subscriptionId] = plans;
        },
        updateCancellationSurvey: (state, action) => {
            const subscriptionId = action?.payload?.data?.subscription_id;
            if (!subscriptionId) return;
            const survey = action.payload.data;
            if (!survey) return;
            state.subscriptions[subscriptionId].cancellation_survey = {
                id: survey.id,
                data: survey.data,
                status: survey.status,
            };
        },
        attachSubscriptionToProject: (state, action) => {
            if (action?.payload.included && action.payload.included.forEach) {
                action.payload.included.forEach((s:any) => {

                    if (state.subscriptions[s.id]) {

                        const projectIds:string[] = (s.projects || []).map((p:any) => {
                            if (typeof p === "number") return String(p);
                            if (typeof p === "object") return String(p.id);
                            return null;
                        });

                        state.subscriptions[s.id].projects = projectIds;
                        state.subscriptions[s.id].project = projectIds?.[0];

                        return;
                    }
                    
                    //addOrUpdateSubscription(state, s)
                })
            }
        },
        detachSubscriptionFromProject: (state, action) => {
            const removedSubId = String(action?.payload.additional?.subscriptionId);
            const removedProjectId = String(action?.payload.additional?.projectId);

            if (!removedSubId || !removedProjectId) return;

            const removedSub = state.subscriptions[removedSubId];
            if (removedSub) {
                state.subscriptions[removedSubId].projects = state.subscriptions[removedSubId].projects.filter((p:string) => p !== removedProjectId);
                state.subscriptions[removedSubId].project = state.subscriptions[removedSubId].projects?.[0];
            }
        }
    }, extraReducers(builder) {
        builder
        .addCase("LOAD_USER_SUCCESS", (state, action) => {
            if (action?.payload?.product_subscriptions) {
                Object.values(action.payload.product_subscriptions).forEach((s:any) => {
                    addOrUpdateSubscription(state, s)
                })
            }
        })
        // .addCase("ATTACH_SUBSCRIPTION_TO_PROJECT_SUCCESS", (state, action) => {

        //     if (action?.included && action.included.forEach) {
        //         action.included.forEach((s:any) => {

        //             if (state.subscriptions[s.id]) {

        //                 const projectIds:string[] = (s.projects || []).map((p:any) => {
        //                     if (typeof p === "number") return String(p);
        //                     if (typeof p === "object") return String(p.id);
        //                     return null;
        //                 });

        //                 state.subscriptions[s.id].projects = projectIds;

        //                 return;
        //             }
                    
        //             //addOrUpdateSubscription(state, s)
        //         })
        //     }
        // })
        // .addCase("DETACH_SUBSCRIPTION_TO_PROJECT_SUCCESS", (state, action) => {

        //     const removedSubId = String(action?.additional?.subscriptionId);
        //     const removedProjectId = String(action?.additional?.projectId);

        //     if (!removedSubId || !removedProjectId) return;

        //     const removedSub = state.subscriptions[removedSubId];
        //     if (removedSub) {
        //         state.subscriptions[removedSubId].projects = state.subscriptions[removedSubId].projects.filter((p:string) => p !== removedProjectId);
        //     }

        // })
    },
}
)

export const getSubscriptions = (): callAPIProps => {

    const call: callAPIProps = {
        url: ({ getApiUrl }) => getApiUrl("user/subscriptions",2),
        successDispatch: subscriptionsSlice.actions.getSubscriptions,
        customNormalizer: (data) => data,
        adjustResponse: (_normalized, raw, normalizer) => {
            //workaround for inconsistent API
            const adj = _.cloneDeep(raw);
            if (adj && adj.data) {
                adj.data.forEach((s: any) => {
                    if (s?.relationships?.stripeInvoices) {
                        s.relationships.stripe_invoices = s.relationships.stripeInvoices;
                    }
                })
            }
            const normalized = normalizer(adj);
            return normalized;
        }
    }

    if (import.meta.env.VITE_DEBUG_FAKE_SUBSCRIPTIONS === "true") {
        return {
            ...call,
            fakeResponse: async (request) => ({
                status: 200,
                json: () => generateFakeSubscriptions()
            })
        }
    }

    return call;

}
