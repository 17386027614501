import { faCheckCircle, faXmarkCircle } from "@fortawesome/pro-light-svg-icons";
import AwesomeIcon from "components/common/AwesomeIcon";
import FormattedMessage from "components/common/FormattedMessage";
import dayjs from "dayjs";
import { ReactNode } from "react";
import { InfinityListSettingsCheckbox } from "tools/infinityList/infinityListSettingsCheckbox";
import { InfinityColumnProps } from "tools/infinityList/infinityTemplates";

export const TransactionsListAdvancedSettings = [
    {
        key: "type",
        label: (
            <FormattedMessage
                id="seller.calculations.list.filtering.advancedSettings.filtering.type.label"
                defaultMessage="Filter by type" />
        ),
        popupClassName: "post-list-advanced-settings-dropdown",
        children: [
            {
                key: "calculations",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="seller.calculations.list.filtering.advancedSettings.calculations.label"
                                defaultMessage="Show Calculations" />
                        }
                        path={["calculationsList", "calculations"]}
                    />
                )

            },
            {
                key: "teamCalculations",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="seller.calculations.list.filtering.advancedSettings.teamCalculations.label"
                                defaultMessage="Show Team Calculations" />
                        }
                        path={["calculationsList", "teamCalculations"]}
                    />
                )

            },
        ]
    },
    {
        key: "status",
        label: (
            <FormattedMessage
                id="seller.calculations.list.filtering.advancedSettings.filtering.status.label"
                defaultMessage="Filter by status" />
        ),
        popupClassName: "post-list-advanced-settings-dropdown",
        children: [
            {
                key: "paid",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="seller.calculations.list.filtering.advancedSettings.paid.label"
                                defaultMessage="Show Paid" />
                        }
                        path={["calculationsList", "paid"]}
                    />
                )

            },
            {
                key: "unpaid",
                label: (
                    <InfinityListSettingsCheckbox
                        message={
                            <FormattedMessage
                                id="seller.calculations.list.filtering.advancedSettings.unpaid.label"
                                defaultMessage="Show Unpaid" />
                        }
                        path={["calculationsList", "unpaid"]}
                    />
                )

            },
        ]
    },
]

const TransactionColumnHeadings: { [key: string]: ReactNode } = {
    "title": <FormattedMessage
        id="seller.calculations.list.header.title"
        defaultMessage="Title"
    />,
    "user": <FormattedMessage
        id="seller.calculations.list.header.user"
        defaultMessage="User"
    />,
    "status": <FormattedMessage
        id="seller.calculations.list.header.status"
        defaultMessage="Status"
    />,
    "type": <FormattedMessage
        id="seller.calculations.list.header.type"
        defaultMessage="Type"
    />,
    "subscription": <FormattedMessage
        id="seller.calculations.list.header.subscription"
        defaultMessage="Subscription"
    />,
    "amount": <FormattedMessage
        id="seller.calculations.list.header.amount"
        defaultMessage="Amount"
    />,
}

const columnSize = {
    small: ["75px", "75px", "75px", "75px", "75px", "75px"],
    standard: [false, false, "75px", "100px", "150px", "250px"],
    wide: ["1fr", "1fr", "1fr", "1fr", "1fr", "1fr"],
}

export const TransactionListHeaders: InfinityColumnProps[] = [
    {
        key: 'title',
        size: columnSize.wide,
        render: () => TransactionColumnHeadings['title'],
    },
    {
        key: 'user',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['user'],
    },
    {
        key: 'status',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['status'],
    },
    {
        key: 'type',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['type'],
    },
    {
        key: 'subscription',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['subscription'],
    },
    {
        key: 'amount',
        size: columnSize.standard,
        render: () => TransactionColumnHeadings['amount'],
    },
];

const TransactionTypeStatuses: any = {
    paid: {
        title: <FormattedMessage
            id="transaction.status.paid"
            defaultMessage="Paid"
        />,
        icon: <AwesomeIcon icon={faCheckCircle} size="xl" color="green" />
    },
    unpaid: {
        title: <FormattedMessage
            id="transaction.status.unpaid"
            defaultMessage="UnPaid"
        />,
        icon: <AwesomeIcon icon={faXmarkCircle} size="xl" color="red" />
    }
}


export const TransactionColumns = [
    {
        title: 'Title',
        size: columnSize.wide,
        render: (record: any) => record.title,
    },
    {
        title: 'User',
        size: columnSize.standard,
        render: (record: any) =>
            <>
                <span>{record?.user?.name}</span>
                <span>{record?.user?.email}</span>
            </>,
    },
    {
        title: 'Status',
        size: columnSize.standard,
        render: (record: any) => TransactionTypeStatuses[record.status] ? TransactionTypeStatuses[record.status].icon : record.status,
    },
    {
        title: 'Type',
        size: columnSize.standard,
        render: (record: any) => record.type,
    },
    {
        title: 'Subscription',
        size: columnSize.standard,
        render: (record: any) =>
            <>
                <span>{record?.subscription?.name}</span>
                <span>{dayjs(record?.subscription?.creation_time).format("DD-MM-YYYY")}</span>
                <span>{record?.subscription?.additional}</span>
            </>,
    },
    {
        title: 'Amount',
        size: columnSize.standard,
        render: (record: any) => record.amount,
    }
];

export const TransactionColumnsToExport = [
    {
      key: "title",
      label: "Title",
      value: (value:any) => value.title
    },
    {
      key: "amount",
      label: "Amount",
      value: (value:any) => value.amount
    },
    {
      key: "status",
      label: "Status",
      value: (value:any) => value.status
    },
    {
      key: "user.name",
      label: "User Name",
      value: (value:any) => value?.user?.name
    },
    {
      key: "user.email",
      label: "User Email",
      value: (value:any) => value?.user?.email
    },
    {
      key: "subscription.name",
      label: "Subscription Name",
      value: (value:any) => value?.subscription?.name
    },
    {
      key: "subscription.creation_time",
      label: "Subscription Creation Time",
      value: (value: any) => {
        const creationTime = value?.subscription?.creation_time;
        
        const date = new Date(creationTime);
        if (date instanceof Date && !isNaN(date.getTime())) {
          return date.toLocaleDateString("pl-PL");
        }
  
        return "";
      },
    },
    {
      key: "subscription.additional",
      label: "Subscription Additional",
      value: (value:any) => value?.subscription?.additional
    },
  ];
